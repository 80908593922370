span.expanded-ref {
    font-weight: bold;
}

.reference-content {
    white-space: pre-wrap
}

span.referenceNumber {
    background-color: #395723;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid black;
    cursor: pointer;
  }

ul.referenceList li {
    list-style-type: none
}
