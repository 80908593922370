span.citation {
  background-color: #395723;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid black;
  cursor: zoom-in;
  vertical-align: super;
  font-size: 0.6em;
}
