body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.auth-section {
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.login-box {
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 2rem 0.5rem 2rem;
}

.logo {
  max-width: 50%;
}

.logo-img {
  max-width: 100%;
  height: auto;
}

.auth-title {
  font-size: 32px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.input-field {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.submit-btn {
  background-color: #395723;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #2a411a;
}

.switch-text {
  font-size: 14px;
  text-align: center;
}

.switch-btn {
  background-color: transparent;
  border: none;
  color: #395723;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
  transition: color 0.3s;
}

.switch-btn:hover {
  color: #2a411a;
}

.signup-fineprint {
  font-size: 8px;
  font-style: italic;
}

.features-section {
  background-color: #333;
  color: #fff;
  display: flex;
  flex: 1;
  padding: 2rem;
}

.features-text {
  flex: 1;
}

.features-title {
  font-size: 32px;
  margin-bottom: 1rem;
}

.features-description {
  font-size: 18px;
  line-height: 1.6;
}

.features-icons {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

.icon-row {
  display: flex;
  justify-content: space-evenly;
}

.icon-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.icon {
  /*font-size: 48px;*/
  text-align: left;
  font-weight: bold;
  margin-bottom: 1rem;
}

.icon-points {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.icon-points li {
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .auth-section {
    flex-direction: column-reverse;
    padding-top: 1rem;
  }

  .logo {
    max-width: 100%;
  }

  .features-section {
    flex-direction: column;
  }

  .features-text {
    margin-bottom: 2rem;
    text-align: center;
  }

  .features-icons {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .icon-row {
    width: 100%;
  }

  .icon-card {
    flex: 1;
    margin-bottom: 2rem;
  }

  .icon-card:not(:last-child) {
    margin-right: 2rem;
  }
}
