/* General styles */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  position: relative;
}

.ui-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  background-color: #fff;
  z-index: 0;
}

/* Chat container */
.chat-container {
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Chat history */
.chat-history {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

/* Chat input */
.chat-input {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0 1.5rem;
  border-top: 1px solid #ddd;
}

.chat-caveat {
  font-size: 0.5rem;
  color: #404040;
  text-align: center;
}

.input-field {
  flex-grow: 1;
  margin-right: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 20px; /* Set the minimum height */
  max-height: 200px; /* Set the maximum height */
  overflow: auto; /* Set overflow to auto so scroll bars appear when the content exceeds the box */
  resize: none; /* Disable the resize handle at the bottom-right corner of the textarea */
  font-family: 'Roboto', sans-serif;
}

.submit-button {
  background-color: #395723;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2a411a;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Chat messages */
.message {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.message-user {
  /* animation: fadeIn 0.5s ease-in; */
  align-self: flex-start;
  background-color: #395723;
  color: #fff;
}

.message-assistant {
  /* animation: fadeIn 0.5s ease-in; */
  background-color: #f1f1f1;
  align-self: flex-end;
}

.message-ui {
  /* animation: fadeIn 0.5s ease-in;*/
  background-color: #FF5733;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

/* Bibliography */

.bibliography {
  overflow-y: auto;
  max-height: 40%
}

/* Menu */
.menu {
  height: 100%;
  width: 15em;
  margin: 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0;
  background-color: #404040;
  box-shadow: 0px 0 10px #404040; 
}

.menu-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.menu-overlay {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
  z-index: -1;
}

.menu-hamburger {
  display: none;
  margin: 0;
}

.logout-button {
  /* TODO: Style the logout button here */
}


/* Mobile optimizations */
@media (max-width: 767px) {
  .ui-container {
    overflow: hidden;
  }

  .chat-container {
    max-width: 100%;
  }

/* Can't figure out the z-index stuff
  .menu-overlay {
    z-index: -1;
    transition: background-color 0.2s ease-out;
  }

  .menu-overlay.menu-open {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
*/

  .menu-hamburger {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    background-color: #404040;
    box-shadow: 0 0 5px #404040;
    border-radius: 0 4px 4px 0;
    color: white;
  }

  .menu-hamburger.menu-open {
    left: 60vw;
    transition: left 0.2s ease-out;
  }

  .menu {
    position: absolute;
    width: 60vw; 
    top: 0;
    bottom: 0;
    left: -100vw;
    opacity: 0%;
    transition: left 0.2s ease-out, opacity 0.2s ease-out;
  }

  .menu.menu-open {
    left: 0;
    opacity: 100%;
    z-index: 1;
  }

  .chat-input { 
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-top: 1px solid #ddd;
  }   

  .input-field {
    font-size: 1rem;
  }

  /* Prevent whole window scrolling on iOS */
  body {
    overscroll-behavior: none;
  }

  .chat-history {
    overscroll-behavior: contain;
  }
}

