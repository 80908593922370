.menu-histories {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  flex-grow: 1;
  padding: 0.5rem;
}

.menu-histories ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.menu-histories li {
  margin-bottom: 5px;
}

.menu-histories button {
  background-color: #eee;
  border: 1px solid #dcdcdc;
  color: #333333;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 5px;
  text-align: left;
  text-decoration: none;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-histories button:hover {
  background-color: #f4f4f4;
  border-color: #c4c4c4;
  color: #000000;
}

.menu-histories button.current-history {
  background-color: #395723;
  border-color: #395723;
  color: #ffffff;
}

.menu-histories button.current-history:hover {
  background-color: #4c752f;
  border-color: #4c752f;
}
